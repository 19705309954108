export const FlatironProps = {
  name1: "Flatiron",
  name2: "School",
  description:
    "While at Flatiron School, I built tools to help current students learn how to code and for prospective students, rebuilt the legacy WordPress marketing website using a JAMStack with Contentful.",
  tech:
    "React, Redux, Ruby on Rails, JAMStack, Contentful, React Testing Library",
  gif1: "https://mc-portfolio-images.s3.amazonaws.com/fisgif2.gif",
  // gif2: "https://mc-portfolio-images.s3.amazonaws.com/fisgif1.gif",
  // gif3: "https://mc-portfolio-images.s3.amazonaws.com/fisgif3.gif",
  colorScheme: "light-blue",
  shape: "triangle",
};

export const KickstarterProps = {
  name1: "Kickstarter",
  description:
    "At Kickstarter, we're focused on building tools that help creators bring their projects to life. If you're familiar with Kickstarter, you know that a backer can back a project for one reward at a time. My team is working on adding the ability to back a project for multiple rewards, an initiative that will boost both revenue and improve the backer's user experience.",
  tech: "React, TypeScript, Ruby on Rails, GraphQL, Jest, Enzyme",
  gif1: "https://mc-portfolio-images.s3.amazonaws.com/ksrgif1.gif",
  // gif2: "https://mc-portfolio-images.s3.amazonaws.com/ksrgif2.gif",
  // gif3: "https://mc-portfolio-images.s3.amazonaws.com/ksrnewsurveygif.gif",
  colorScheme: "light-green",
  shape: "octagon",
};

export const isMobile = () => window.innerWidth < 1040;
